<template>
  <v-container :style="{ padding: '0', marginTop: '12px' }">
    <v-row class="tw-my-1/3">
      <v-col
        class="text-center tw-mb-6"
        cols="12"
        v-if="trip && trip.destinations && trip.destinations.length > 0"
        :style="{ padding: '0' }"
      >
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-5 tw-px-6"
        >
          We're going to...
        </h2>
        <div
          v-if="editMode"
          class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-mb-4 tw-px-6"
        >
          <div class="tw-relative tw-w-full">
            <j-text-field
              v-if="trip"
              v-model="trip.destinations[0].name"
              light
              background-color="#fafafa"
              color="#203848"
              hide-details
              placeholder="Edit Trip"
              class="tw-w-full tw-mr-2"
              prepend-icon="mdi-web"
            />
            <v-icon
              class="tw-absolute tw-top-4 tw-right-4 tw-text-warm-grey"
              @click="toggleEditMode"
              >mdi-window-close</v-icon
            >
          </div>
          <v-btn
            v-if="trip"
            icon
            class="tw-w-10 tw-h-10"
            @click="editFinalizedDestination(trip.destinations[0])"
          >
            <v-icon size="20" class="tw-text-charcoal">mdi-check</v-icon>
          </v-btn>
        </div>
        <div
          v-else
          class="tw-flex tw-flex-row tw-items-start tw-justify-between tw-h-14 tw-mb-4 tw-px-6"
        >
          <p
            class="tw-text-left tw-font-bebas-neue tw-text-42 tw-text-charcoal tw-leading-none tw-mb-0"
            v-if="trip"
          >
            {{ trip.destinations[0].name }}
          </p>
          <img
            v-if="isTripOwner || isOrganizer"
            src="@/assets/svgs/surveys/pencil.svg"
            alt="Edit Question"
            class="tw-cursor-pointer"
            @click="toggleEditMode"
          />
        </div>
        <div>
          <img
            src="@/assets/svgs/destinations/destination-placeholder-img.png"
            class="tw-w-full tw-mb-3"
            alt="Destination"
          />
          <!-- <p
            class="tw-text-right tw-text-xs tw-underline tw-text-dark-green tw-font-semibold tw-cursor-pointer"
          >
            Edit Image
          </p> -->
        </div>
      </v-col>
      <!--Destination isnt finalized-->
      <v-col class="text-center" cols="12" :style="padding" v-else>
        <img
          src="@/assets/svgs/destinations/airplane-pin.svg"
          alt="Airplane Pin"
          class="tw-rounded-md tw-block tw-mx-auto"
          v-if="
            isTripOwner ||
            isOrganizer ||
            !destinations ||
            destinations.length === 0
          "
        />
        <h1
          class="tw-mt-4 tw-mb-2 tw-font-semibold"
          :class="[
            {
              'tw-text-center tw-text-lg':
                isTripOwner ||
                isOrganizer ||
                (!isTripOwner && destinations && destinations.length === 0),
              'tw-text-left tw-text-22':
                !isTripOwner &&
                !isOrganizer &&
                destinations &&
                destinations.length > 0
            }
          ]"
        >
          Where to?
        </h1>
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
          v-if="isTripOwner || isOrganizer"
        >
          <v-form @submit.prevent="finalizeDestination">
            <validation-provider rules="required">
              <j-text-field
                v-model="finalizedDestination"
                placeholder="Enter Destination"
                background-color="#f5f5f5"
                prepend-inner-icon="mdi-map-marker"
                class="tw-mb-3"
              />
            </validation-provider>
            <v-btn
              block
              rounded
              x-large
              color="primary"
              large
              :disabled="invalid"
              type="submit"
              class="tw-mb-20 tw-tracking-normal"
            >
              Finalize Destination
            </v-btn>
          </v-form>
        </validation-observer>
        <p
          class="tw-font-figtree tw-font-normal tw-text-light-grey tw--mt-2 tw-mb-6"
          :class="[
            {
              'tw-text-center': isTripOwner || isOrganizer,
              'tw-text-left':
                !isTripOwner &&
                !isOrganizer &&
                destinations &&
                destinations.length > 0
            }
          ]"
          v-else
        >
          Not sure yet? Let’s decide together. Add ideas & vote on your
          favorites.
        </p>
      </v-col>
      <v-col cols="12" :style="padding">
        <p class="j-text-field-label tw-text-18 tw-font-bold tw-mb-0">
          Save on Travel
        </p>
        <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-4">
          We earn a small amount when you buy through our links
        </p>
        <div class="tw-flex tw-justify-between tw-mb-6">
          <!-- <a
            href="https://www.tkqlhce.com/click-101084691-15736720"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-mr-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Hopper</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a> -->
          <a
            href="https://prf.hn/click/camref:1101l3BN3k/pubref:letsjetty/ar:app"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-full"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Expedia</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
        </div>
      </v-col>
      <v-col
        cols="12"
        class="tw-text-left tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-4"
        :style="padding"
        v-if="
          isTripOwner ||
          isOrganizer ||
          (destinations && destinations.length > 0)
        "
      >
        <span class="tw-text-18 tw-text-dark-green tw-font-bold"
          >Crew Suggestions</span
        >
        <v-menu
          offset-y
          bottom
          left
          v-if="destinations && destinations.length > 1"
        >
          <template v-slot:activator="{ on }">
            <div
              class="tw-flex tw-flex-row tw-items-center tw-justify-start"
              v-on="on"
            >
              <span
                class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block tw-text-xxs tw-mr-2 tw-cursor-pointer"
                >SORT BY</span
              >
              <img src="@/assets/svgs/chevron.svg" alt="Chevron" />
            </div>
          </template>
          <v-list class="tw-text-xs tw-text-charcoal">
            <v-list-item
              class="tw-cursor-pointer"
              @click="fetchAndSortDestinations('createdAt')"
            >
              Most Recent</v-list-item
            >
            <v-list-item class="tw-cursor-pointer" @click="fetchAndSortByLikes"
              >Top Posts</v-list-item
            >
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        :style="padding"
        class="tw-text-left"
        v-if="
          isTripOwner ||
          isOrganizer ||
          (destinations && destinations.length > 0)
        "
      >
        <p
          class="tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-mt-2 tw-mb-6"
        >
          Not sure yet? Let’s decide together. Add ideas & vote on your
          favorites.
        </p>
      </v-col>
      <v-col cols="12" :style="padding" class="tw-mb-6">
        <v-btn
          block
          :to="{ name: 'SuggestDestinationRoute' }"
          rounded
          x-large
          class="tw-tracking-normal"
          color="secondary"
          large
        >
          <v-icon>mdi-plus</v-icon>Suggest a Destination
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <div
        class="tw-bg-separator-grey tw-w-full tw-h-2"
        v-if="destinations && destinations.length > 0"
      ></div>
      <v-col cols="12" :style="{ padding: '0' }" v-if="destinations">
        <Post
          v-for="destination in destinations"
          :key="destination.id"
          :destination="destination"
          :user="user"
          :is-trip-owner="isTripOwner || isOrganizer"
          @destination-updated="updateDestination($event)"
          @delete="toggleDialog($event)"
        />
      </v-col>
    </v-row>
    <DeleteDestinationDialog
      :is-visible="isVisible"
      :uuid="uuidToDelete"
      @cancel="toggleDialog"
      @close="toggleDialog"
    />
  </v-container>
</template>

<script>
import Post from "@/components/destinations/Post.vue";
import DeleteDestinationDialog from "@/components/destinations/DeleteDestinationDialog.vue";
export default {
  name: "DestinationList",
  components: {
    Post,
    DeleteDestinationDialog
  },
  data() {
    return {
      destinations: null,
      finalizedDestination: null,
      isVisible: false,
      uuidToDelete: null,
      editMode: false,
      padding: {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "0",
        paddingBottom: "0"
      }
    };
  },
  methods: {
    toggleDialog(uuid) {
      this.uuidToDelete = uuid || null;
      this.isVisible = !this.isVisible;
      if (!uuid) this.fetchDestinations();
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    async fetchDestinations() {
      const res = await this.$store.dispatch("post-destination/find", {
        query: {
          tripId: this.$route.params.id,
          $sort: {
            createdAt: -1
          }
        }
      });
      this.destinations = [];
      this.destinations = res.data;
    },
    async fetchAndSortDestinations(sortField) {
      const res = await this.$store.dispatch("post-destination/find", {
        query: {
          tripId: this.$route.params.id,
          $sort: {
            [sortField]: -1
          }
        }
      });
      this.destinations = [];
      this.destinations = res.data;
    },
    async fetchAndSortByLikes() {
      const res = await this.$store.dispatch("post-destination/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      const destinations = res.data;

      destinations.sort((a, b) => {
        const likesA = a.post_likes.length;
        const likesB = b.post_likes.length;

        return likesB - likesA;
      });
      this.destinations = destinations;
    },
    updateDestination(updatedDestination) {
      const index = this.destinations.findIndex(
        (dest) => dest.uuid === updatedDestination.uuid
      );
      if (index !== -1) this.$set(this.destinations, index, updatedDestination);
    },
    async finalizeDestination() {
      const destination = {
        name: this.finalizedDestination,
        startDate: this.trip.startDate,
        endDate: this.trip.endDate,
        tripId: this.trip.id
      };
      await this.$store.dispatch("destination/create", destination);
      this.finalizedDestination = null;
      await this.fetchTrip();
      this.$forceUpdate();
      if (sessionStorage.getItem("itinerary")) {
        this.$router.push({ name: "TripItineraryList" });
      }
    },
    async editFinalizedDestination(destination) {
      await this.$store.dispatch("destination/patch", [
        destination.id,
        destination
      ]);
      this.toggleEditMode();
    },
    async fetchTrip() {
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination"
          }
        }
      ]);
    }
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    user() {
      return this.$store.state.auth.user;
    },
    isTripOwner() {
      if (this.trip) return this.trip.ownerId === this.user.id;
      else return null;
    },
    isOrganizer() {
      if (!this.trip || !this.user.trips || this.user.trips.length === 0)
        return false;
      let currentTrip = this.user.trips.find((t) => t.id === this.trip.id);
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    }
  },
  async beforeMount() {
    await this.fetchTrip();
    await this.fetchDestinations();
  },
  destroyed() {
    sessionStorage.removeItem("itinerary");
  }
};
</script>
