<template>
  <div class="destinations-view tw-mb-10">
    <destination-list />
  </div>
</template>
<script>
import DestinationList from "@/components/destinations/DestinationList.vue";
export default {
  name: "DestinationsView",
  components: {
    DestinationList
  },
  beforeMount() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Destination",
      bgColor: "#0036F5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  },
  destroyed() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });
  }
};
</script>
