var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-bg-white tw-rounded-md tw-border-b-8 tw-pb-11 tw-border-b-solid tw-border-grey-two"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-6 tw-pt-6 tw-px-10"},[_c('div',{staticClass:"tw-flex tw-justify-start tw-items-center"},[_c('UserAvatar',{staticClass:"tw-mr-4",attrs:{"user":_vm.destination.user,"size":"56"}}),_c('div',{staticClass:"tw-text-charcoal tw-text-left"},[_c('div',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v(" "+_vm._s(_vm.destination.user.firstName)+" "+_vm._s(_vm.destination.user.lastName)+" ")]),_c('div',{staticClass:"tw-text-xs"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(_vm.destination.createdAt))+" ")])])],1),(_vm.isTripOwner || _vm.isPostOwner)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"tw-ml-auto tw-self-center tw-text-grey-one",attrs:{"size":"36"}},on),[_vm._v("mdi-dots-vertical")])]}}],null,false,2144023249)},[_c('v-list',{staticClass:"tw-text-xs"},[_c('v-list-item',{staticClass:"tw-cursor-pointer"},[_c('router-link',{staticClass:"tw-text-charcoal",attrs:{"to":{
              name: 'EditDestinationRoute',
              params: { id: _vm.destination.tripId, uuid: _vm.destination.uuid }
            }}},[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"size":"16","color":"#333333"}},[_vm._v("mdi-pencil")]),_vm._v(" Edit")],1)],1),_c('v-list-item',{staticClass:"tw-cursor-pointer",on:{"click":function($event){return _vm.$emit('delete', _vm.destination.uuid)}}},[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"size":"16","color":"#333333"}},[_vm._v("mdi-delete")]),_vm._v("Delete ")],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"tw-text-left tw-mb-4"},[_c('p',{staticClass:"tw-text-charcoal tw-font-normal tw-px-10"},[_vm._v(" "+_vm._s(_vm.destination.text)+" ")]),_c('div',{staticClass:"tw-relative"},[_c('img',{staticClass:"tw-w-full",attrs:{"src":require("@/assets/svgs/destinations/destination-placeholder-img.png"),"alt":"Destination"}}),(_vm.destination.name)?_c('span',{staticClass:"tw-rounded-r-md tw-py-1 tw-px-6 tw-bg-white tw-text-charcoal tw-absolute tw-left-0 tw-bottom-6 tw-font-bold"},[_vm._v(_vm._s(_vm.destination.name))]):_vm._e()])]),_c('div',{staticClass:"tw-border-t tw-border-b tw-border-solid tw-border-lightest-grey tw-py-3 tw-mx-10 tw-flex tw-flex-row tw-justify-between tw-text-xxs tw-text-charcoal"},[_c('button',{staticClass:"j-reaction-pill",class:{
        'tw-bg-chartreuse': _vm.checkIfUserReacted(_vm.destination, 'UP'),
        'tw-bg-lightest-grey':
          (!_vm.checkIfUserReacted(_vm.destination, 'UP') &&
            _vm.checkIfUserReacted(_vm.destination)) ||
          !_vm.checkIfUserReacted(_vm.destination)
      },attrs:{"disabled":_vm.reactionButtonDisabled},on:{"click":function($event){return _vm.postReaction('UP', _vm.destination)}}},[(_vm.upCount > 0)?_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(this.upCount))]):_vm._e(),_vm._v(" My fave "),(_vm.checkIfUserReacted(_vm.destination, 'UP'))?_c('span',[_vm._v("❤️")]):_c('span',[_vm._v("💛")])]),_c('button',{staticClass:"j-reaction-pill",class:{
        'tw-bg-dark-green tw-text-white': _vm.checkIfUserReacted(
          _vm.destination,
          'NEUTRAL'
        ),
        'tw-bg-lightest-grey':
          (!_vm.checkIfUserReacted(_vm.destination, 'NEUTRAL') &&
            _vm.checkIfUserReacted(_vm.destination)) ||
          !_vm.checkIfUserReacted(_vm.destination)
      },attrs:{"disabled":_vm.reactionButtonDisabled},on:{"click":function($event){return _vm.postReaction('NEUTRAL', _vm.destination)}}},[(_vm.neutralCount > 0)?_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(this.neutralCount))]):_vm._e(),_vm._v(" I'm down 👍 ")]),_c('button',{staticClass:"j-reaction-pill",class:{
        'tw-bg-dark-green tw-text-white': _vm.checkIfUserReacted(
          _vm.destination,
          'DOWN'
        ),
        'tw-bg-lightest-grey':
          (!_vm.checkIfUserReacted(_vm.destination, 'DOWN') &&
            _vm.checkIfUserReacted(_vm.destination)) ||
          !_vm.checkIfUserReacted(_vm.destination)
      },attrs:{"disabled":_vm.reactionButtonDisabled},on:{"click":function($event){return _vm.postReaction('DOWN', _vm.destination)}}},[(_vm.downCount > 0)?_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(this.downCount))]):_vm._e(),_vm._v(" Ehhh 😅 ")])]),_c('div',{staticClass:"tw-py-3 tw-flex tw-flex-row tw-justify-end tw-text-xxs tw-text-charcoal tw-px-10"},[_c('span',{staticClass:"tw-cursor-pointer tw-underline",on:{"click":_vm.toggleDisplayAllComments}},[_vm._v(_vm._s(_vm.destination.post_comments.length)+" comments")])]),_c('div',{staticClass:"tw-px-10"},[_vm._l((_vm.displayedComments),function(comment){return _c('Comment',{key:comment.id,attrs:{"comment":comment,"userId":_vm.user.id},on:{"deleted":_vm.deletedComment}})}),_c('CommentBuilder',{attrs:{"user":_vm.user,"item":_vm.destination},on:{"comment-posted":_vm.updateDestination}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }